import { useState } from "react"
import styled from "styled-components"

import { endOfDay, startOfDay, subDays } from "date-fns"

import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { CustomizeReportDialog } from "src/components/Reports/ConfigureNoiseMonitoringSummaryDialog"
import { ReportCard } from "src/components/Reports/ReportCard"
import { usePostReportDownloadClicked } from "src/data/analytics/queries/ReportAnalyticsQueries"
import { TMaybeHomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import {
  TReportRequestBody,
  usePostReportRequest,
} from "src/data/reports/reportQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { TDateRange } from "src/ui/DateRangePicker/DateRangePicker"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { downloadFromUrl } from "src/utils/downloadUtil"
import { debug } from "src/utils/logger"

type ReportsCardProps = {
  reportsAvailable: boolean
  reportsProAvailable: boolean
}
export function NoiseSummaryReportCard({
  reportsAvailable,
  reportsProAvailable,
}: ReportsCardProps) {
  const { t, langKeys } = useTranslate()

  const postReportRequest = usePostReportRequest()
  const postReportDownloadClicked = usePostReportDownloadClicked()

  function handleDownloadReport() {
    postReportDownloadClicked.mutate({ type: "basic_noise" })
    postReportRequest.mutate(
      { report_type: "noise" },
      { onSuccess: ({ url }) => downloadFromUrl(url) }
    )
  }

  if (!reportsAvailable) return null

  if (!reportsProAvailable) {
    return (
      <ReportCard
        title={t(langKeys.noise_report_title)}
        description={
          reportsProAvailable
            ? t(langKeys.noise_report_pro_body)
            : t(langKeys.noise_report_body)
        }
        footer={
          <>
            <FlexBox>
              <MText variant="heading3">
                {t(langKeys.customize_noise_report_title)}
              </MText>
              <UpgradeIconButton context={"reports"} />
            </FlexBox>
            <MText color="secondary">
              {t(langKeys.customize_noise_report_body)}
            </MText>
          </>
        }
        content={
          <MButton
            variant="secondary"
            onClick={handleDownloadReport}
            loading={postReportRequest.isLoading}
          >
            {t(langKeys.download_report)}
          </MButton>
        }
      />
    )
  }

  return (
    <ReportCard
      title={t(langKeys.noise_report_title)}
      description={
        reportsProAvailable
          ? t(langKeys.noise_report_pro_body)
          : t(langKeys.noise_report_body)
      }
      content={<NoiseSummaryReportPro />}
    />
  )
}

const FlexBox = styled.div`
  display: flex;
  gap: ${spacing.S};
  align-items: center;
`

function NoiseSummaryReportPro() {
  // MON-464: Sync state with query params
  const { t, langKeys } = useTranslate()
  const postReportRequest = usePostReportRequest()
  const postReportDownloadClicked = usePostReportDownloadClicked()

  const [showConfigureDialog, setShowConfigureDialog] = useState(false)

  const [homeGroup, setHomeGroup] = useState<TMaybeHomeGroup>(null)

  const now = Date.now()
  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: startOfDay(subDays(now, 30)),
    endDate: endOfDay(now),
  })

  function handleCreateReport() {
    postReportDownloadClicked.mutate({ type: "filtered_noise" })
    setShowConfigureDialog(true)
  }

  function handleClose() {
    postReportRequest.reset()
    setShowConfigureDialog(false)
  }

  function handleDownloadProReport() {
    const requestData: TReportRequestBody = {
      report_type: "noise",
      homegroup_id: homeGroup?.id,
      start_at: dateRange.startDate?.toISOString(),
      end_at: dateRange.endDate?.toISOString(),
    }
    debug.log({ requestData })

    postReportRequest.mutate(requestData, {
      onSuccess: ({ url }) => {
        downloadFromUrl(url)
        handleClose()
      },
    })
  }

  function getPossibleError() {
    if (!postReportRequest.isError) {
      return undefined
    }
    const errKey = postReportRequest.error?.response?.data.error_key
    if (errKey === "invalid_date_range") {
      return t(langKeys.noise_report_error_invalid_date_range)
    }
    if (errKey === "feature_unavailable_for_organization") {
      // Skip custom error message for now
    }
    return t(langKeys.failed_something_went_wrong)
  }

  return (
    <div>
      <MButton
        variant="secondary"
        onClick={handleCreateReport}
        loading={postReportRequest.isLoading}
      >
        {t(langKeys.create_report)}
      </MButton>

      <CustomizeReportDialog
        dialogProps={{
          open: !!showConfigureDialog,
          onClose: handleClose,
          onConfirm: handleDownloadProReport,
          confirmButtonProps: { loading: postReportRequest.isLoading },
          error: getPossibleError(),
        }}
        reportOptions={{ dateRange, setDateRange, homeGroup, setHomeGroup }}
      />
    </div>
  )
}

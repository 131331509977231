import { ReportCard } from "src/components/Reports/ReportCard"
import { usePostReportDownloadClicked } from "src/data/analytics/queries/ReportAnalyticsQueries"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import UpgradeIcon from "src/ui/icons/arrow-up-filled-upgrade.svg"
import { MBadge } from "src/ui/MBadge/MBadge"

export function IndoorClimateReportCard() {
  const { navigate } = useRouter()

  const { t, langKeys, tPlaceholder } = useTranslate()
  const { moldRiskInsights } = useFlags()

  const postReportDownloadClicked = usePostReportDownloadClicked()

  function handleDownloadReport() {
    postReportDownloadClicked.mutate({ type: "indoor_climate" })

    return window.open(
      "https://forms.gle/Bze8PP2Y2Loe9WSw6",
      "_blank",
      "noopener,noreferrer"
    )
  }

  if (moldRiskInsights) {
    const titleComponents = [
      <UpgradeIcon key={"upgrade-icon"} />,
      <MBadge key={t(langKeys.new_badge_title)} color="promotional">
        {t(langKeys.new_badge_title)}
      </MBadge>,
    ]

    return (
      <ReportCard
        title={tPlaceholder("Mold risk analytics")}
        titleComponents={titleComponents}
        description={tPlaceholder(
          "Discover mold risk trends over time and get a detailed breakdown of units with high or elevated mold risk."
        )}
        content={
          <MButton
            size="medium"
            variant="secondary"
            onClick={() => navigate(Routes.MoldInsights.location().pathname)}
          >
            {tPlaceholder("View analytics")}
          </MButton>
        }
      />
    )
  }

  return (
    <ReportCard
      title={t(langKeys.indoor_climate_report_title)}
      description={t(langKeys.indoor_climate_report_body)}
      content={
        <MButton
          size="medium"
          variant="secondary"
          onClick={handleDownloadReport}
        >
          {t(langKeys.download_report)}
        </MButton>
      }
    />
  )
}

import styled from "styled-components"

import { MDivider } from "src/ui/Divider/Divider"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ReportCard({
  title,
  titleComponents = [],
  description,
  content,
  footer,
}: {
  title: string
  titleComponents?: React.ReactNode[]
  description?: React.ReactNode
  content: React.ReactNode
  footer?: React.ReactNode
}) {
  return (
    <MCard padding={spacing.XL} boxShadow={false} border>
      <CardTitle>
        <MText variant="heading3">{title}</MText>

        {(titleComponents?.length ?? 0) > 0 && titleComponents?.map((c) => c)}
      </CardTitle>
      {description && <MText color="secondary">{description}</MText>}

      <ContentBox> {content}</ContentBox>

      {footer && (
        <div>
          <ReportsDivider />
          {footer}
        </div>
      )}
    </MCard>
  )
}

const CardTitle = styled.div`
  display: flex;
  gap: ${spacing.XS};
  padding-bottom: ${spacing.XS};
`

const ReportsDivider = styled(MDivider)`
  margin: ${spacing.L} 0;
`

const ContentBox = styled.div`
  margin-top: ${spacing.L};
`
